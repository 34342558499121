const apiRoot = process.env.REACT_APP_API_ROOT

const Config = {
    apiRoot: apiRoot
}

export const API = {
    resources: {
        influencer: `${apiRoot}/influencer`,
        client: `${apiRoot}/client`,
        media: `${apiRoot}/media`,
        project: `${apiRoot}/project`,
        projectLink: `${apiRoot}/project_link`,
        projectInfluencer: `${apiRoot}/project_influencer`,
        tag: `${apiRoot}/tag`,
        mst: `${apiRoot}/mst`,
        s3: `${apiRoot}/s3`,
    }
}

export default Config