import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, {useAppState} from '../../../store/store';
import * as ui from '../../../ui'
import { Link } from 'react-router-dom';
import ClientList from './ClientList'

const ClientListScreen = () => {

    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={true}>
            <ui.HeaderContents showed={true}>
                <ui.HeaderTitle>クライアント</ui.HeaderTitle>
                <Link to="/client/form"><ui.HeaderButton>登録</ui.HeaderButton></Link>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageContents showed={true}>
            <ClientList />
        </ui.PageContents>
    </ui.PageWrapper>
}

export default ClientListScreen