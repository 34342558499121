import Influencer from "../entities/Influencer";
import { API } from '../Config'
import ServiceUtil from "./ServiceUtil";
import Client from "../entities/Client";

class ClientService {

    fetchList = () => {
        return fetch(API.resources.client).then((response) => {
            return response.json();
        }).then(json => {
            return json.map(row => new Client(row))
        })
    }

    create = (name, email, password, tagListStr) => {
        return ServiceUtil.post(API.resources.client, {
            name: name,
            email: email,
            password: password,
            tagList: tagListStr
        }).then(res => res.json()).then(json => new Client(json, true))
    }

    update = (id, name, email, password, tagListStr) => {
        return ServiceUtil.put(API.resources.client + "/" + id, {
            name: name,
            email: email,
            password: password ? password : undefined,
            tagList: tagListStr
        }).then(res => res.json()).then(json => new Client(json))
    }

    delete = (id) => {
        return fetch(`${API.resources.client}/${id}`, {
            method: "DELETE",
        }).then(res => res.json())
    }
}

const clientService = new ClientService()

export { clientService }