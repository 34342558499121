class ProjectInfluencer {

    constructor(data) {
        this._data = data
        console.log(data)
    }

    get id() {
        return this._data.id
    }

    get project_id() {
        return this._data.project_id
    }

    get influencer_id() {
        return this._data.influencer_id
    }
}

export default ProjectInfluencer