import Influencer, {InfluencerTmp} from "../entities/Influencer";
import {API} from '../Config'
import ServiceUtil from "./ServiceUtil";

class InfluencerService {

    fetchWithUrl = (url) => {
        return fetch(url, {mode: 'cors'}).then((response) =>{
            return response.text();
        }).then( (text) =>{
            const ar = text.split("window._sharedData = ")[1].split(";</script>")
            const sharedData = JSON.parse(ar[0])
            return new InfluencerTmp(sharedData)
        });
    }

    fetchList = () =>{
        return fetch(API.resources.influencer).then((response)=>{
            return response.json();
        }).then(json=>{
            return json.map(row=>new Influencer(row))
        })
    }

    create = (influencer) => {
        return ServiceUtil.post(API.resources.influencer, influencer.postData).then(response=>{
            return response.json();
        }).then(json=>new Influencer(json))
    }

    delete = (id) => {
        return fetch(`${API.resources.influencer}/${id}`, {
            method: "DELETE",
        }).then(res=>res.json())
    }
}

const influencerService = new InfluencerService()

export { influencerService }