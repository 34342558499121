import './App.css'
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store, { AppContext, AppProvider } from './store/store';
import InfluencerListScreen from './screens/influencer/list/InfluencerListScreen'
import InfluencerViewScreen from './screens/influencer/view/InfluencerViewScreen'
import SearchInfluencerScreen from './screens/influencer/search/SearchInfluencerScreen'

import ClientListScreen from './screens/client/list/ClientListScreen'
import styled from 'styled-components'
import Menu from './Menu'
import { colors } from './style'
import ClientFormScreen from './screens/client/form/ClientFormScreen'
import ProjectListScreen from './screens/project/list/ProjectListScreen';
import ProjectFormScreen from './screens/project/form/ProjectFormScreen';
import TagListScreen from './screens/tag/list/TagListScreen';


const Wrapper = styled.div`
  position: relative;
  min-width:100vw;
  min-height:100vh;
`

const ScreenWrapper = styled.div`
  position:relative;
  padding-left: 150px;
  min-height:100vh;
`

const App = () => {

  useEffect(() => {
    console.log("use Effect")
    store.fetchInfluencer()
    store.fetchClient()
    store.fetchMedia()
    store.fetchProject()
    store.fetchProjectInfluencer()
    store.fetchTag()
    store.fetchMst()

    return () => {
      console.log("clear effect")
    }
  }, [])

  return <Wrapper>
    <Router>
      <Menu />
      <div>
        <ScreenWrapper>
          <Route path='/influencer/' component={InfluencerListScreen} />
          <Route path='/influencer/view/:id' component={InfluencerViewScreen} />
          <Route path='/influencer/search' component={SearchInfluencerScreen} />
          <Route path='/client' component={ClientListScreen} />
          <Route path='/client/form/:id?' component={ClientFormScreen} />
          <Route path='/project' component={ProjectListScreen} />
          <Route path='/project/form/:id?' component={ProjectFormScreen} />
          <Route path='/tag' component={TagListScreen} />
        </ScreenWrapper>
      </div>
    </Router>
  </Wrapper>
}

export default App;
