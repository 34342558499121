import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { colors, size } from './style'
import styled from 'styled-components'
import { withRouter } from 'react-router';



const Wrapper = styled.div`
    position:fixed;
    width:${size.menuWidth}px;
    height:100vh;
    overflow-y:scroll;
    background-color:${colors.primary};
    z-index:10;
`

const MenuRow = styled.p`
    a{
        padding:10px;
        font-size:16px;
        color:#ffffff;
        display:block;
    }

    ${({ active }) => active && `
        a{
            background-color:#ffffff;
            color:${colors.primary};
        }
    `}
`

const Menu = () => {

    const pageList = [
        { path: "/client", title: "クライアント" },
        { path: "/project", title: "案件" },
        { path: "/influencer", title: "インフルエンサー" },
        { path: "/tag", title: "タグ" },
    ]

    const currentPath = window.location.pathname

    return <Wrapper>
        {pageList.map(page => {
            const active = currentPath.startsWith(page.path)
            return <MenuRow key={page.path} active={active}><Link to={page.path}>{page.title}</Link></MenuRow>
        })}
    </Wrapper>
}

export default withRouter(Menu)