import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { colors, size } from '../style'
export { InfluencerList } from './influencer'
export { Popup } from './popup'

export const PageWrapper = styled.div`
    position:absolute;
    top:0px;right:0px;
    left:${size.menuWidth}px;
    z-index:20;
    min-height:100vh;
`

export const PageBgLayer = styled.div`
  position:absolute;
  top:${size.headerHeight}px;left:0px;right:0px;
  height:calc(100vh - ${size.headerHeight}px);
  z-index:1;
  background-color:#000000;
  opacity:0;
  transition: opacity 200ms ease;
  ${({ showed }) => showed && `
    opacity:0.4;
  `}
`

export const PageTitle = styled.h1`
    font-size:30px;
    font-weight:bold;
    margin-bottom:20px;
`

export const PageSubTitle = styled.h2`
    font-size:20px;
    margin-bottom:20px;
`

export const InputWrapper = styled.div`
  margin-bottom:0px;
`

export const InputInfo = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items: center;
    height:20px;
`

export const InputLabel = styled.h6`
    font-size:14px;
`

export const Input = styled.input`
    border:1px solid ${colors.border};
    border-radius:5px;
    width:100%;
    height:44px;
    flex:1;
    padding:0px 10px;

    &:focus{
        border-color: #999999;
    }

    ${({ valid }) => valid && `
        border-color:  ${colors.valid};
        &:focus{
            border-color: ${colors.valid};
        }
    `}

    ${({ disable }) => disable && `
        pointer-events: none;
        background-color:${colors.disable};
    `}
`

export const TextArea = styled.textarea`
    border:1px solid ${colors.border};
    border-radius:5px;
    width:100%;
    flex:1;
    padding:10px 10px;
    font-size:20px;

    &:focus{
        border-color: #999999;
    }

    ${({ valid }) => valid && `
        border-color:  ${colors.valid};
        &:focus{
            border-color: ${colors.valid};
        }
    `}

    ${({ disable }) => disable && `
        pointer-events: none;
        background-color:${colors.disable};
    `}
`

export const Select = styled.select`
    border:1px solid ${colors.border};
    border-radius:5px;
    width:100%;
    height:44px;
    padding:0px 10px;

    &:focus{
        border-color: #999999;
    }

    ${({ valid }) => valid && `
        border-color:  ${colors.valid};
        &:focus{
            border-color: ${colors.valid};
        }
    `}

    ${({ disable }) => disable && `
        pointer-events: none;
        background-color:${colors.disable};
    `}
`

export const Loader = styled.div`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #9999ff;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
  box-sizing: border-box;
  ::before,
  ::after {
    box-sizing: border-box;
    left: -2px;
    top: -2px;
    display: none;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    border: inherit;
    border-radius: inherit;
  }

  border-top-color: transparent;
  ::after {
    display: block;
    left: -2px;
    top: -2px;
    border: inherit;
    transform: rotate(65deg);
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const FormButton = styled.button`
  background-color:${colors.white};
  display:block;
  width:100%;
  padding:10px;
  text-align:center;
  border-radius:5px;
  color:${colors.primary};
  border:1px solid ${colors.primary};
  margin-top:50px;  

  ${({ disable }) => disable ? `
      pointer-events: none;
      border-color:${colors.gray};
      color:${colors.gray};
  ` : `
    :hover{
      background-color:${colors.primary};
      color:${colors.white};
    }
  `
  }
`
export const Button = styled.button`
  background-color:${colors.white};
  display:block;
  padding:12px;
  text-align:center;
  border-radius:5px;
  color:${colors.primary};
  border:1px solid ${colors.primary};
  ${({ disable }) => disable ? `
      pointer-events: none;
      border-color:${colors.gray};
      color:${colors.gray};
  ` : `
    :hover{
      background-color:${colors.primary};
      color:${colors.white};
    }
  `}
`

export const HeaderWrapper = styled.div`
  position:absolute;
  top:0px;right:0px;left:0px;
  height:${size.headerHeight}px;
  background: rgba(255,255,255, 0.0);
  border-bottom:1px solid ${colors.border};
  z-index:400;
  transition: background-color 200ms ease;
  ${({ showed }) => showed && `
    background: rgba(255,255,255, 1.0);
  `}
`

export const HeaderContents = styled.div`
  display:flex;
  justify-content: space-between;
  width:100%;
  height:100%;
  align-items:center;
  padding:10px 20px;
  opacity:0;
  margin-left:5vw;
  transition: opacity 200ms ease, margin 200ms ease;
  ${({ showed }) => showed && `
    opacity:1.0;
    margin-left:0vw;
  `}
`

export const HeaderTitle = styled.h1`
  margin:0px;
  font-size:25px;
  flex:1;
`

export const HeaderTitleCenter = styled(HeaderTitle)`
  text-align:center;
`

export const PageContents = styled.div`
  height:100vh;
  overflow-y:scroll;
  padding:20px;
  z-index:20;
  position:relative;
  background-color:#ffffff;
  padding-top:${size.headerHeight}px;

  left: 40vw;
  opacity:0;

  transition: left 300ms ease, opacity 300ms ease;
  ${({ showed }) => showed && `
    left:0px;
    opacity:1.0;
  `}
`

export const HeaderButton = styled.button`
  height:44px;
  line-height:44px;
  text-align:center;
  padding: 0px 20px;
  color:${colors.primary};
  border:1px solid ${colors.primary};
  border-radius: 5px;

  ${({ disable }) => disable ? `
      pointer-events: none;
      border-color:${colors.gray};
      color:${colors.gray};
  ` : `
    :hover{
      background-color:${colors.primary};
      color:${colors.white};
    }
  `
  }
`

export const HeaderBackButton = styled(HeaderButton)`
  border:none;
  :hover{
    background-color:${colors.white};
    color:${colors.primary};
    text-decoration:underline;
  }
`

export const HeaderButtonDanger = styled(HeaderButton)`
  color:${colors.danger};
  border:1px solid ${colors.danger};

  ${({ disable }) => disable ? `
  ` : `
    :hover{
      background-color:${colors.danger};
      color:${colors.white};
    }
  `
  }
`

export const HeaderSpacer = styled.div`
  width:20px;
`
