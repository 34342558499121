import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { colors, size } from '../../../style'
import store, { useAppState } from '../../../store/store'
import * as ui from '../../../ui'
import util from '../../../util'
import { Link } from 'react-router-dom';
import { influencerService } from '../../../service/InfluencerService';


const ProjectRow = styled.div`
    padding:20px;
    border-bottom:1px solid ${colors.border};
    display:flex;
    justify-content:space-between;
    align-items:center;
    transition: background-color 300ms ease;

    ${({created})=> created && `
        background-color: ${colors.primaryLight};
    `}
`

const DeleteButton = styled.button`
    padding:10px 5px;
    text-align:center;
    width:80px;
    border:1px solid ${colors.danger};
    border-radius: 5px;
    color: ${colors.danger};
    :hover{
        background-color:${colors.danger};
        color:${colors.white};
    }
`

const ProjectInfo = styled.div`
    flex:1;
    a{
        color:${colors.primary};
        font-size:20px;
        :hover{
            text-decoration:underline;
        }
    }
`

const ProjectListScreen = () => {
    const state = useAppState();
    const {projectList, clientList} = state
    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={true}>
            <ui.HeaderContents showed={true}>
                <ui.HeaderTitle>案件</ui.HeaderTitle>
                <Link to="/project/form"><ui.HeaderButton>登録</ui.HeaderButton></Link>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageContents showed={true}>
            {projectList.map(project=>renderRow(project, clientList.find(client=>project.client_id == client.id)))}
        </ui.PageContents>
    </ui.PageWrapper>
}

const deleteProject = (e) => {
    const id = parseInt(e.target.dataset["id"])
    store.deleteProject(id).then(result=>{
        console.log("result", result)
    })
}

const renderRow = (project, client) => {
    return <ProjectRow key={project.id}>
        <ProjectInfo>
            <Link to={`/project/form/` + project.id}>{project.name}</Link>
            {client && <p>{client.name}</p>}
        </ProjectInfo>
        <DeleteButton data-id={project.id} onClick={deleteProject}>削除</DeleteButton>
    </ProjectRow>
}

export default ProjectListScreen