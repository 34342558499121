import styled from 'styled-components'

class util {
  static keta = (num) => {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  static get now() {
    return (new Date()).getTime()
  }

  static resizeImage = (base64Str, maxWidth = 400, maxHeight = 350) => {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement('canvas')
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }

  static imgToBase64 = (img, mime_type) => {

    const InstaImageSize = 640

    // New Canvas
    var canvas = document.createElement('canvas');
    canvas.width = InstaImageSize;
    canvas.height = InstaImageSize;
    // Draw Image
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    // To Base64
    return canvas.toDataURL(mime_type);
  }

  static visionList = (mdeiaList) => {
    let results = []
    mdeiaList.forEach(media => {
      media.visionList.forEach(vision => {
        let current = results.find(row => {
          return row.mid == vision.mid
        })
        if (current) {
          current.count = current.count + 1
        } else {
          results.push({
            count: 1,
            mid: vision.mid,
            description: vision.description,
            score: vision.score
          })
        }
      })
    });

    return results.sort((a, b) => {
      if (b.count > a.count) { return 1 }
      if (a.count > b.count) { return -1 }
      return 0
    })
  }

  static hashTagList = (mdeiaList) => {
    let results = []
    mdeiaList.forEach(media => {
      media.hashTagList.forEach(hashTag => {
        let current = results.find(row => {
          return row.hashTag == hashTag
        })
        if (current) {
          current.count = current.count + 1
        } else {
          results.push({
            count: 1,
            hashTag: hashTag
          })
        }
      })
    });

    return results.sort((a, b) => {
      if (b.count > a.count) { return 1 }
      if (a.count > b.count) { return -1 }
      return 0
    })
  }

  static avarageLiked = (mediaList) => {
    if (mediaList.length == 0) return 0
    let total = mediaList.map(row => row.liked).reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return parseInt(total / mediaList.length)
  }

  static avarageCommented = (mediaList) => {
    if (mediaList.length == 0) return 0

    let total = mediaList.map(row => row.commented).reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return parseInt(total / mediaList.length)
  }

  static testEmail = (email) => {
    const regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
    return regexp.test(email)
  }
}

export default util