import { API } from '../Config'
import ServiceUtil from "./ServiceUtil";
import Project from "../entities/Project";

class ProjectService {

    fetchList = () => {
        return fetch(API.resources.project).then((response) => {
            return response.json();
        }).then(json => {
            return json.map(row => new Project(row))
        })
    }

    create = (name, client_id, tagList, projectname) => {
        return ServiceUtil.post(API.resources.project, {
            name: name,
            client_id: client_id,
            tag_list: tagList,
            projectname: projectname
        }).then(res => res.json()).then(json => new Project(json, true))
    }

    update = (id, name, client_id, influencer_id_list, tagList, projectname, lpTitle, youtubeUrl, description) => {
        return ServiceUtil.put(API.resources.project + "/" + id, {
            name: name,
            client_id: client_id,
            influencer_id_list: influencer_id_list,
            tag_list: tagList,
            projectname: projectname,
            lp_title: lpTitle,
            youtube_url: youtubeUrl,
            description: description
        }).then(res => res.json()).then(json => new Project(json))
    }

    updateProjectLink = (id, linkList) => {
        return ServiceUtil.put(API.resources.projectLink + "/" + id, {
            link_list: JSON.stringify(linkList),
        })
    }

    delete = (id) => {
        return fetch(`${API.resources.project}/${id}`, {
            method: "DELETE",
        }).then(res => res.json())
    }

    patch = (id, key, value) => {
        return ServiceUtil.patch(`${API.resources.project}/${id}`, { key: key, value: value }).then(response => {
            return response.json()
        })
    }
}

export const projectService = new ProjectService()
