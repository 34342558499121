class Project {

    constructor(data) {
        this._data = data

        try {
            this.linkList = JSON.parse(this._data.link_list)
            if (!Array.isArray(this.linkList)) {
                this.linkList = []
            }
        } catch (e) {
            this.linkList = []
        }

    }

    get id() {
        return this._data.id
    }

    get name() {
        return this._data.name
    }

    get projectname() {
        return this._data.projectname
    }

    get clientId() {
        return this._data.client_id
    }

    get lpTitle() {
        return this._data.lp_title
    }

    get youtubeUrl() {
        return this._data.youtube_url
    }

    get description() {
        return this._data.description
    }

    get client_id() {
        return this._data.client_id
    }

    get tagList() {
        if (!this._data.tag_list) {
            return []
        }

        try {
            return JSON.parse(this._data.tag_list)
        } catch (e) {
            return []
        }
    }

    get hasTitleImage() {
        return !!this._data.title_image
    }

    get hasEcTitleImage() {
        return !!this._data.ec_title_image
    }

    get titleImage() {
        return `https://vreach-lp.s3-ap-northeast-1.amazonaws.com/${this._data.title_image}`
    }

    set titleImage(val) {
        this._data.title_image = val
    }

    get ecTitleImage() {
        return `https://vreach-lp.s3-ap-northeast-1.amazonaws.com/${this._data.ec_title_image}`
    }

    set ecTitleImage(val) {
        this._data.ec_title_image = val
    }


}

export default Project