import React, { createContext, useState } from 'react';
import { appState } from '../state/AppState'
import { influencerService } from '../service/InfluencerService'
import { mediaService } from '../service/MediaService'
import { clientService } from '../service/ClientService';
import { projectService } from '../service/ProjectService';
import { projectInfluencerService } from '../service/ProjectInfluencerService';
import Media from '../entities/Media';
import ProjectInfluencer from '../entities/ProjectInfluencer';
import { tagService } from '../service/TagService';
import { s3Service } from '../service/S3Service';

const initialAppState = { value: appState }

class Store {

    constructor() {
        console.log("Store#constructor")
    }

    _appStateSet = undefined;

    set appStateSet(val) {
        this._appStateSet = val
    }

    get appStateSet() { return this._appStateSet }

    increment = () => {
        const [appState, setAppState] = this.appStateSet
        appState.value.increment()
        setAppState({ value: appState.value })
    }

    set currentInfluencer(val) {
        const [appState, setAppState] = this.appStateSet
        appState.value.currentInfluencer = val
        setAppState({ value: appState.value })
    }

    addedVisionList = () => {
        this.notifyUpdate()
    }

    notifyUpdate = () => {
        const [appState, setAppState] = this.appStateSet
        setAppState({ value: appState.value })
    }

    uploadTitleImage = (clientId, projectId, base64Data) => {
        return this.uploadImage([clientId, projectId, "title"], base64Data).then((result) => {
            return projectService.patch(1, "title_image", result.key)
        }).then(result => {
            console.log("patched", result)
            return result
        })
    }

    uploadEcTitleImage = (clientId, projectId, base64Data) => {
        return this.uploadImage([clientId, projectId, "ec_title"], base64Data).then((result) => {
            return projectService.patch(1, "ec_title_image", result.key)
        }).then(result => {
            console.log("patched", result)
            return result
        })
    }

    uploadImage = (pathList, base64Data) => {
        return s3Service.uploadWith(pathList, base64Data)
    }

    // mst ----------------
    fetchMst = () => {

    }

    // Vision -------------

    fetchVision = (base64Data) => {
        return mediaService.fetchVision(base64Data).then(results => {
            if (!results) { return [] }
            return results.filter(row => row.score > 0.8)
        })
    }

    fetchVisionWithUrl = (url) => {
        return mediaService.fetchVisionWithUrl(url)
    }

    // Project ---------------------

    fetchProject = () => {
        return projectService.fetchList().then((projectList) => {
            const [appState, setAppState] = this.appStateSet
            appState.value.projectList = projectList
            setAppState({ value: appState.value })
        })
    }

    createProject = (name, client_id, tagList, projectname = "") => {
        return projectService.create(name, client_id, tagList, projectname).then(project => {
            const [appState, setAppState] = this.appStateSet
            appState.value.projectList.unshift(project)
            setAppState({ value: appState.value })
            setTimeout(() => {
                project.created = false
                this.notifyUpdate()
            }, 1000);
            return project
        })
    }

    updateProject = (id, name, client_id, tagList, influencerList = [], projectname = "", lpTitle = "", youtubeUrl = "", description = "") => {
        let influencer_id_list = influencerList.map(row => row.id)
        return projectService.update(id, name, client_id, influencer_id_list, tagList, projectname, lpTitle, youtubeUrl, description).then(project => {
            const [appState, setAppState] = this.appStateSet
            appState.value.projectList = appState.value.projectList.slice().map(row => {
                return (row.id === project.id) ? project : row
            })

            const projectInfluencerList = appState.value.projectInfluencerList.slice()
                .filter(row => row.project_id !== id)

            influencerList.forEach(influencer => {
                projectInfluencerList.push(new ProjectInfluencer({
                    project_id: id,
                    influencer_id: influencer.id
                }))
            })

            appState.value.projectInfluencerList = projectInfluencerList
            setAppState({ value: appState.value })
            return project
        })
    }

    updateProjectLink = (id, linkList) => {

        const [appState, setAppState] = this.appStateSet
        const target = appState.value.projectList.find(row => row.id == id)
        target.linkList = linkList

        return projectService.updateProjectLink(id, linkList)
    }

    deleteProject = (id) => {
        const [appState, setAppState] = this.appStateSet
        appState.value.projectList = appState.value.projectList.slice().filter(row => row.id !== id)
        setAppState({ value: appState.value })

        return projectService.delete(id)
    }

    // Client ----------------------

    fetchClient = () => {
        return clientService.fetchList().then((clientList) => {
            const [appState, setAppState] = this.appStateSet
            appState.value.clientList = clientList
            setAppState({ value: appState.value })
        })
    }

    createClient = (name, email, password, tagListStr) => {
        return clientService.create(name, email, password, tagListStr).then(client => {
            const [appState, setAppState] = this.appStateSet
            appState.value.clientList.unshift(client)
            setAppState({ value: appState.value })
            setTimeout(() => {
                client.created = false
                this.notifyUpdate()
            }, 1000);
        })
    }

    updateClient = (id, name, email, password, tagListStr) => {
        return clientService.update(id, name, email, password, tagListStr).then(client => {
            const [appState, setAppState] = this.appStateSet
            appState.value.clientList = appState.value.clientList.slice().map(row => {
                return (row.id === client.id) ? client : row
            })
            setAppState({ value: appState.value })
            console.log("updatedClient", client)
            return client
        })
    }

    deleteClient = (id) => {
        const [appState, setAppState] = this.appStateSet
        appState.value.clientList = appState.value.clientList.slice().filter(row => {
            console.log("row.id", row.id)
            console.log("id", id)
            return row.id !== id
        })
        setAppState({ value: appState.value })

        return clientService.delete(id)
    }

    // influencer ----------------------

    fetchInfluencer = () => {
        return influencerService.fetchList().then(influencerList => {
            const [appState, setAppState] = this.appStateSet
            appState.value.influencerList = influencerList
            setAppState({ value: appState.value })
        })
    }

    fetchInfluencerWithUrl = (url) => {
        return influencerService.fetchWithUrl(url)
    }

    deleteInfluencer = (id) => {
        const [appState, setAppState] = this.appStateSet
        appState.value.influencerList = appState.value.influencerList.slice().filter(row => row.id !== id)
        setAppState({ value: appState.value })
        return influencerService.delete(id)
    }


    isExistsInfluencer(url) {
        const [appState, setAppState] = this.appStateSet
        const ar = url.split("https://www.instagram.com/")
        if (ar.length == 0) { return false }
        if (ar[1] == undefined) { return false }

        const username = ar[1].split("/")[0]

        return appState.value.influencerList.find(row => row.username == username) !== undefined
    }

    findInfluencer = (id) => {
        const [appState] = this.appStateSet
        return appState.value.influencerList.find(row => row.id == id)
    }

    findClient = (id) => {
        const [appState] = this.appStateSet
        return appState.value.clientList.find(row => row.id == id)
    }

    createInfluencer = (influencer) => {

        const mediaList = influencer.timeLineMediaList

        let createdInfluencer = null

        const [appState, setAppState] = this.appStateSet

        return influencerService.create(influencer).then(result => {
            createdInfluencer = result
            console.log("createdInfluencer", createdInfluencer)
            return Promise.all(mediaList.map(media => mediaService.create(media, createdInfluencer.id)))
        }).then((mediaList) => {
            appState.value.influencerList.unshift(createdInfluencer)
            appState.value.mediaList = appState.value.mediaList.slice().concat(mediaList.map(row => new Media(row)))
            setAppState({ value: appState.value })
            return createdInfluencer
        })
    }

    // MEdia ------------------

    fetchMedia = () => {
        return mediaService.fetchList().then((mediaList) => {
            const [appState, setAppState] = this.appStateSet
            appState.value.mediaList = mediaList
            setAppState({ value: appState.value })
        })
    }

    createMedia = (media) => {
        mediaService.create(media)
    }

    mediaListWithInfluencerId(influencerId) {
        const [appState, setAppState] = this.appStateSet
        return appState.value.mediaList.slice().filter(row => row.influencerId == influencerId)
    }

    // Project-Influencer ---------------------

    fetchProjectInfluencer = () => {
        return projectInfluencerService.fetchList().then((projectInfluencerList) => {
            const [appState, setAppState] = this.appStateSet
            appState.value.projectInfluencerList = projectInfluencerList
            setAppState({ value: appState.value })
        })
    }

    createProjectInfluencer = (name, client_id) => {

        return projectInfluencerService.create(name, client_id).then(project => {
            const [appState, setAppState] = this.appStateSet
            appState.value.projectList.unshift(project)
            setAppState({ value: appState.value })
            setTimeout(() => {
                project.created = false
                this.notifyUpdate()
            }, 1000);
        })
    }

    deleteProjectInfluencer = (id) => {
        const [appState, setAppState] = this.appStateSet
        appState.value.projectList = appState.value.projectList.slice().filter(row => row.id !== id)
        setAppState({ value: appState.value })

        return projectInfluencerService.delete(id)
    }

    // tags ---------------

    fetchTag = () => {
        return tagService.fetchList().then((tagList) => {
            const [appState, setAppState] = this.appStateSet
            appState.value.tagList = tagList
            setAppState({ value: appState.value })
        })
    }

}

const store = new Store()

export const AppContext = createContext();

const { Provider } = AppContext;

export const StoreProvider = ({ children }) => {
    const appStateSet = useState(initialAppState)

    store.appStateSet = appStateSet
    const appState = appStateSet[0]

    return <Provider value={{ appState }}>{children}</Provider>
}

export const useAppState = () => {
    return React.useContext(AppContext).appState.value;
}

export default store
