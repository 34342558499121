import Influencer from "../entities/Influencer";
import { API } from '../Config'
import ServiceUtil from "./ServiceUtil";
import Tag from "../entities/Tag";

class TagService {

    fetchList = () => {
        return fetch(API.resources.tag).then((response) => {
            return response.json();
        }).then(json => {
            return json.map(row => new Tag(row))
        })
    }

}

export const tagService = new TagService()