import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { colors, size } from '../../../style'
import store, { useAppState } from '../../../store/store'
import * as ui from '../../../ui'
import util from '../../../util'
import AttributesView from '../components/AttributesView';
import { Link } from 'react-router-dom';

const { RowWrapper, RowContainer, InfluencerInfo, CountList, ProfileImage } = ui.InfluencerList

const Wrapper = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
`

const AddButton = styled(ui.Button)`
    width:100px;
    margin-top:20px;
`

const RightContents = styled.div`

`

const DeleteButton = styled.p`
    color:${colors.danger};
    font-size:14px;
    cursor:pointer;
    text-align:center;
    margin-top:20px;
    :hover{
        text-decoration:underline;
    }
`

const InfluencerRow = ({ assigned, influencer, onClickAdd, onClickDelete, onClickName = () => { } }) => {

    if (!onClickAdd) {
        return <Contents influencer={influencer} onClickName={onClickName} />
    }

    return <Wrapper>
        <Contents influencer={influencer} onClickName={onClickName} />
        <RightContents>
            <AddButton
                disable={assigned}
                onClick={() => { onClickAdd(influencer) }}>
                {assigned ? "追加済み" : "追加"}
            </AddButton>
            {assigned && <DeleteButton
                onClick={() => { onClickDelete(influencer) }}
            >
                削除
            </DeleteButton>}
        </RightContents>
    </Wrapper>
}

const Contents = ({ influencer, onClickName = () => { } }) => {
    const mediaList = store.mediaListWithInfluencerId(influencer.id)
    const visionList = util.visionList(mediaList).slice(0, 15)
    return <RowWrapper key={influencer.id}>
        <RowContainer>
            <ProfileImage>
                <img src={influencer.profilePic} />
            </ProfileImage>
            <InfluencerInfo>
                <h3><a onClick={() => { onClickName(influencer) }}>{influencer.fullName}</a> <span><a href={`https://www.instagram.com/${influencer.username}/`} target="_blank">{influencer.username}</a></span></h3>
                <CountList>
                    <li key="1">投稿<br /><span>{util.keta(influencer.allMediaCount)}</span>件</li>
                    <li key="2">フォロワー<br /><span>{util.keta(influencer.follower)}</span>人</li>
                    <li key="3">フォロー<br /><span>{util.keta(influencer.follow)}</span>人</li>
                    <li key="4">平均いいね<br /><span>{util.keta(util.avarageLiked(mediaList))}</span>人</li>
                    <li key="5">平均コメント<br /><span>{util.keta(util.avarageCommented(mediaList))}</span>人</li>
                </CountList>
                {visionList.length > 0 && <AttributesView visionList={visionList} />}
            </InfluencerInfo>
        </RowContainer>
    </RowWrapper>
}

export default InfluencerRow