import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    h2{
        font-size:30px;
    }
`

const VisionList = styled.ul`
    margin-top:0px;
    li{
        display:inline-block;
        margin-right:10px;
        line-height:1.4;
        span{
            font-weight:bold;
        }
    }
`

const AttributesView = ({ visionList }) => {
    return <Wrapper>
        <VisionList>
            {visionList
                .filter(row=>{ return row.count > 1})
                .slice(0, 30)
                .map(vision => <li key={vision.mid}>{vision.description}(<span>{vision.count}</span>)</li>)}
        </VisionList>
    </Wrapper>
}


export default AttributesView