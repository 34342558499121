import Config, { API } from "../Config"
import ServiceUtil from "./ServiceUtil"


class S3Service {

    constructor() {

    }

    setup(mst) {
        // this.fetchList()
    }

    __presignedUrl = (key, contentType) => {
        return ServiceUtil.post(API.resources.s3, {
            key: key,
            contentType, contentType,
        }).then(res => res.json())
    }

    // shortcut
    uploadWith = (pathList, base64Data) => {
        return this.upload(pathList.join("/"), base64Data)
    }

    upload = (filename, base64data) => {

        // Buffer
        const fileData = base64data.replace(/^data:\w+\/\w+;base64,/, '')
        const decodedFile = new Buffer(fileData, 'base64')

        // ファイルの拡張子(png)
        const fileExtension = base64data.toString().slice(base64data.indexOf('/') + 1, base64data.indexOf(';'))

        // ContentType(image/png)
        const contentType = base64data.toString().slice(base64data.indexOf(':') + 1, base64data.indexOf(';'))

        const key = filename + "." + fileExtension
        return this.__presignedUrl(key, contentType).then(json => {
            console.log(json)
            return json.url
        }).then(presignedUrl => {
            return fetch(presignedUrl, {
                method: "PUT",
                body: decodedFile,
                headers: {
                    "Content-Encoding": "base64",
                    "Content-Type": contentType,
                    "Content-Length": decodedFile.byteLength
                }
            })
        }).then(() => {
            return {
                "key": key
            }
        })
    }
}

const s3Service = new S3Service()

export { s3Service }

