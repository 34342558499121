import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, {useAppState} from '../../../store/store';
import * as ui from '../../../ui'
import { colors, size } from '../../../style';
import util from '../../../util';

const Wrapper = styled.div`
    padding:30px;
    width:400px;
    margin-right:40px;
`

const InputWrapper = styled(ui.InputWrapper)`
    margin-bottom:15px;
`

const TagInfo = styled.p`
    font-size:10px;
`

const ClientForm = ({client}) => {

    var defaults = {
        name: "", 
        email: "",
        password: "",
        tagList: []
    }

    if(client != undefined){
        defaults = {
            name: client.name, 
            email: client.email,
            tagList: client.tagList
        }
    }

    const [name, setName] = useState(defaults.name)
    const [email, setEmail] = useState(defaults.email)
    const [password, setPassword] = useState("")
    const [tagList, setTagList] = useState(defaults.tagList.join(","))
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)
    
    const disableCreate = (()=>{
        if(name.length == 0 || !util.testEmail(email) || loading || done){
            return  true
        }
        if (client == undefined) {
            return password.length == 0 
        }
        return false
    })()
    

    const appState = useAppState()

    const trySubmit = () => {
        if(disableCreate){ return }

        setLoading(true)

        const tag_list_str = JSON.stringify(tagList.split(",").filter((row)=> row.length > 0))
        if(client == undefined){
            // create
            store.createClient(name, email, password, tag_list_str).then(result=>{
                setLoading(false)
                setName("")
                setEmail("")
                setPassword("")
                setTagList([])
                setDone(true)
                setTimeout(()=>{
                    setDone(false)
                }, 1000)
            })
        }else{
            // update
            store.updateClient(client.id, name, email, password, tag_list_str).then(result=>{
                setLoading(false)
                setDone(true)
                setTimeout(()=>{
                    setDone(false)
                }, 1000)
            })
        }
        
    }

    const handleKeyDown = (e) =>{
        if (e.key === "Enter" && e.keyCode === 13) { 
            trySubmit()
        }
    }

    const buttonTitle = (()=>{
        if (client !== undefined){
            if(done) return "変更しました"
            if(loading) return "変更中"
            return "変更"
        }
        if(done) return "登録しました"
        if(loading) return "登録中"
        return "登録"
    })()

    return <Wrapper>
        <ui.PageTitle>アカウント情報</ui.PageTitle>
        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>名前</ui.InputLabel>
            </ui.InputInfo>
            <ui.Input
                type="text"
                valid={name.length > 0}
                value={name}
                disable={loading}
                onChange={(e) => { setName(e.target.value) }}
                onKeyDown={handleKeyDown}
            />
        </InputWrapper>
        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>メールアドレス</ui.InputLabel>
            </ui.InputInfo>
            <ui.Input
                type="email"
                valid={util.testEmail(email)}
                value={email}
                disable={loading}
                onChange={(e) => { setEmail(e.target.value) }}
                onKeyDown={handleKeyDown}
            />
        </InputWrapper>
        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>パスワード</ui.InputLabel>
            </ui.InputInfo>
            <ui.Input
                type="password"
                valid={password.length > 0}
                value={password}
                disable={loading}
                onChange={(e) => { setPassword(e.target.value) }}
                onKeyDown={handleKeyDown}
            />
        </InputWrapper>

        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>提案タグ (カンマ区切り)</ui.InputLabel>
            </ui.InputInfo>
            <ui.Input
                type="taglist"
                value={tagList}
                disable={loading}
                onChange={(e) => { setTagList(e.target.value.replace(/\s+/g, "")) }}
                onKeyDown={handleKeyDown}
            />
            <TagInfo>{appState.tagList.map(tag=>tag.name).join(",")}</TagInfo>
        </InputWrapper>

        <ui.FormButton 
            onClick={trySubmit}
            disable={disableCreate}>{buttonTitle} {loading && <ui.Loader style={{top:3, left:10}} />}
        </ui.FormButton>
    </Wrapper>
}

export default ClientForm