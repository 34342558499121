import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { useHistory } from 'react-router-dom';
import InfluencerView from './InfluencerView'
import { colors, size } from '../../../style'
import store, { useAppState } from '../../../store/store'
import * as ui from '../../../ui'


const SearchInfluencerScreen = () => {

    const [influencerUrl, setInfluencerUrl] = useState("")
    const validUrl = influencerUrl.startsWith("https://www.instagram.com/")
    const [loading, setLoading] = useState(false)

    const appState = useAppState()
    const influencer = appState.currentInfluencer
    const exists = store.isExistsInfluencer(influencerUrl)

    const buttonDisabled = exists || !validUrl || loading

    const trySearch = () => {
        if (!validUrl) { return }

        if (influencer) {
            tryCreate()
            return;
        }

        setLoading(true)
        store.fetchInfluencerWithUrl(influencerUrl).then((influencer) => {
            store.currentInfluencer = influencer
            setLoading(false)
        })
    }

    const tryCreate = () => {
        store.createInfluencer(influencer).then(influencer => {
            console.log("created", influencer)
        })
    }

    const buttonTitle = (() => {
        if (exists) return "登録済"
        if (influencer) {
            if (loading) { return "登録中" }
            return "登録"
        }

        if (loading) { return "検索中" }
        return "検索"
    })()

    const [showed, setShowed] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowed(true)
        }, 100)
    }, []);

    const history = useHistory()
    const handleBack = () => {
        setShowed(false)
        setTimeout(history.goBack, 300)
    }

    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={showed}>
            <ui.HeaderContents showed={showed}>
                <ui.HeaderBackButton onClick={handleBack}>&lt; 戻る</ui.HeaderBackButton>
                <ui.HeaderSpacer />
                <div style={{ flex: 1 }}>
                    <ui.InputWrapper>
                        <ui.Input
                            type="text"
                            valid={validUrl}
                            value={influencerUrl}
                            disable={loading}
                            onChange={(e) => {
                                setInfluencerUrl(e.target.value)
                                store.currentInfluencer = undefined
                            }}
                            onKeyDown={(e) => {
                                if (e.key !== 'Enter') { return }
                                trySearch()
                            }}
                        />
                    </ui.InputWrapper>
                </div>
                <ui.HeaderSpacer />
                <ui.HeaderButton disable={buttonDisabled} onClick={trySearch}>{buttonTitle}</ui.HeaderButton>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageBgLayer showed={showed} />
        <ui.PageContents showed={showed}>
            {influencer && <InfluencerView influencer={influencer} />}
        </ui.PageContents>
    </ui.PageWrapper>
}

export default SearchInfluencerScreen