import styled from 'styled-components'
import { colors, size } from '../style'

const RowWrapper = styled.div`
    padding: 20px 0px;
    border-bottom:1px solid ${colors.border};
    flex:1;
`

const RowContainer = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items: flex-start;
`

const InfluencerInfo = styled.div`
    flex:1;
    h3{
        font-size:20px;
        span{
            font-size:15px;
            color: #999999;
        }
        a{
            cursor:pointer;
            color:${colors.primary};
            :hover{
                text-decoration:underline;
            }
        }
    }
    h4{
        margin-top:20px;
        font-size:20px;
    }
    p{
        margin-top:10px;
    }
`

const CountList = styled.ul`
    display:flex;
    flex-wrap: wrap;
    margin-top:10px;
    li{
        margin-right:20px;
        font-size:12px;
    margin-bottom:10px;
        span{
            font-weight:bold;
            font-size:18px;
        }
    }
`

const ProfileImage = styled.div`
    width:100px;
    height:100px;
    border-radius:50px;
    border:4px solid #9999ff;
    overflow:hidden;
    margin-right:20px;
    img{
        width:100%;
        height:100%;
    }
`

export const InfluencerList = {RowWrapper, RowContainer, InfluencerInfo, CountList, ProfileImage}