import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import * as ui from '../../../ui'
import { colors, size } from '../../../style';
import util from '../../../util';
import { useHistory } from 'react-router-dom';
import Config from '../../../Config';

const Wrapper = styled.div`
    padding:30px;
    padding-bottom:0px;
`

const ConfigView = styled.div`
    display: grid;
    grid-column-gap: 60px;
    width:100%;
    grid-template-columns: 1fr 1fr;
`

const InputWrapper = styled(ui.InputWrapper)`
    margin-bottom:15px;
`

const ProjectnameWrapper = styled.div`
    display:flex;
    width:100%;
    align-items:center;
    p{
        font-size:20px;
        margin-right:5px;
    }
`

const TagInfo = styled.p`
    font-size:10px;
`

const LpLink = styled.p`
    font-size:16px;
    margin-bottom:5px;
    a{
        color:${colors.primary};
        :hover{
            text-decoration:underline;
        }
    }
`
const PageTitle = styled(ui.PageTitle)`
    margin-top:30px;
`

const ProjectForm = ({ project }) => {

    const appState = useAppState()
    const history = useHistory()
    const clientList = appState.clientList
    const showButton = !project

    var defaults = {
        name: "",
        projectname: "",
        client_id: undefined,
        tagList: [],
        lpTitle: "",
        youtubeUrl: ""
    }

    if (project != undefined) {
        defaults = {
            name: project.name,
            client_id: project.client_id,
            tagList: project.tagList,
            projectname: project.projectname ?? "",
            lpTitle: project.lpTitle ?? "",
            youtubeUrl: project.youtubeUrl ?? "",
            description: project.description ?? ""
        }
    }
    const [name, setName] = useState(defaults.name)
    const [clientId, setClientId] = useState(defaults.client_id)
    const [projectname, setProjectname] = useState(defaults.projectname)
    const [lpTitle, setLpTitle] = useState(defaults.lpTitle)
    const [youtubeUrl, setYoutubeUrl] = useState(defaults.youtubeUrl)
    const [description, setDescription] = useState(defaults.description)
    const [tagList, setTagList] = useState(defaults.tagList.join(","))
    const [loading, setLoading] = useState(false)
    const [done, setDone] = useState(false)

    const disableCreate = (() => {
        if (name.length == 0 || loading || done) {
            return true
        }
        return false
    })()

    const trySubmit = () => {
        if (disableCreate) { return }

        setLoading(true)

        const tag_list_str = JSON.stringify(tagList.split(",").filter((row) => row.length > 0))
        if (project == undefined) {
            // create

            store.createProject(name, clientId, tag_list_str, projectname).then(result => {
                setLoading(false)
                setDone(true)
                setTimeout(() => {
                    setDone(false)
                    history.replace(`/project/form/${result.id}`)
                }, 1000)
            })
        } else {
            store.updateProject(project.id, name, clientId, tag_list_str, [], projectname, lpTitle, youtubeUrl, description).then(result => {
                setLoading(false)
                setDone(true)
                setTimeout(() => {
                    setDone(false)
                }, 1000)
            })
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && e.keyCode === 13) {
            trySubmit()
        }
    }

    const buttonTitle = (() => {
        if (project !== undefined) {
            if (done) return "変更しました"
            if (loading) return "変更中"
            return "変更"
        }
        if (done) return "登録しました"
        if (loading) return "登録中"
        return "登録"
    })()

    return <Wrapper>
        <ui.PageTitle>案件情報</ui.PageTitle>
        <ConfigView>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>クライアント</ui.InputLabel>
                </ui.InputInfo>
                <ui.Select
                    valid={clientId !== undefined}
                    value={clientId}
                    disable={loading}
                    onChange={(e) => { setClientId(e.target.value) }}
                    onKeyDown={handleKeyDown}
                >
                    <option value={undefined}>選択してください</option>
                    {clientList.map(row => <option value={row.id} key={row.id}>{row.name}</option>)}
                </ui.Select>
            </InputWrapper>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>案件名</ui.InputLabel>
                </ui.InputInfo>
                <ui.Input
                    type="text"
                    valid={name.length > 0}
                    value={name}
                    disable={loading}
                    onChange={(e) => { setName(e.target.value) }}
                    onKeyDown={handleKeyDown}
                />
            </InputWrapper>
        </ConfigView>

        <PageTitle>LP情報</PageTitle>
        <ConfigView>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>LPタイトル</ui.InputLabel>
                </ui.InputInfo>
                <ui.Input
                    type="text"
                    value={lpTitle}
                    disable={loading}
                    onChange={(e) => { setLpTitle(e.target.value) }}
                    onKeyDown={handleKeyDown}
                />
            </InputWrapper>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>URL</ui.InputLabel>
                    <LpLink><a
                        href={"https://vrea.ch/" + projectname}
                        target="_blank"
                    >開く</a></LpLink>
                </ui.InputInfo>
                <ProjectnameWrapper>
                    <p>vrea.ch/</p>
                    <ui.Input
                        type="text"
                        value={projectname}
                        disable={loading}
                        onChange={(e) => { setProjectname(e.target.value) }}
                        onKeyDown={handleKeyDown}
                    />
                </ProjectnameWrapper>
            </InputWrapper>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>対象タグ (カンマ区切り)</ui.InputLabel>
                </ui.InputInfo>
                <ui.Input
                    type="text"
                    value={tagList}
                    disable={loading}
                    onChange={(e) => { setTagList(e.target.value.replace(/\s+/g, "")) }}
                    onKeyDown={handleKeyDown}
                />
                <TagInfo>{appState.tagList.map(tag => tag.name).join(",")}</TagInfo>
            </InputWrapper>
            <InputWrapper>
                <ui.InputInfo>
                    <ui.InputLabel>youtube</ui.InputLabel>
                </ui.InputInfo>
                <ui.Input
                    type="text"
                    value={youtubeUrl}
                    disable={loading}
                    onChange={(e) => { setYoutubeUrl(e.target.value) }}
                    onKeyDown={handleKeyDown}
                />
            </InputWrapper>
        </ConfigView>

        <InputWrapper>
            <ui.InputInfo>
                <ui.InputLabel>説明</ui.InputLabel>
            </ui.InputInfo>
            <ui.TextArea
                value={description}
                disable={loading}
                rows={10}
                onChange={(e) => { setDescription(e.target.value) }}
            />
        </InputWrapper>

        {showButton && <ui.FormButton
            onClick={trySubmit}
            disable={disableCreate}>{buttonTitle} {loading && <ui.Loader style={{ top: 3, left: 10 }} />}
        </ui.FormButton>}
    </Wrapper>
}

export default ProjectForm