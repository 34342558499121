import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../store/store';
import * as ui from '../ui'
import { Link } from 'react-router-dom';
import ProjectForm from '../screens/project/form/ProjectForm'
import { colors, size } from '../style'
import TagMediaView from './TagMediaView';

const Wrapper = styled.div`
    flex:1;
    padding:30px;
    height:100%;
    overflow-y:scroll;
`

const MediaListWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
`

class TagMediaListViewModel {
    constructor(appState, tagList){
        this.appState = appState
        this.tagList = tagList
    }

    get mediaList(){
        const {project, appState, tagList} = this
        const { mediaList } = appState

        return mediaList.filter(media=>media.hasTag(tagList))
            .sort((a, b)=>{
                return b.liked > a.liked
            })
            .slice(0, 100)
    }

    get tagString(){

        return this.tagList.join(" / ")
    }
}

const TagMediaListView = ({tagList}) => {

    const viewModel = new TagMediaListViewModel(useAppState(), tagList)
    const {mediaList, tagString} = viewModel

    return <Wrapper>
        <ui.PageTitle>「{tagString}」を含む投稿 <span>({mediaList.length} 件)</span></ui.PageTitle>
        <MediaListWrapper>
            {mediaList.map(media=><TagMediaView media={media} key={media.id} />)}
        </MediaListWrapper>
    </Wrapper>
}


export default TagMediaListView