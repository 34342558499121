import styled from 'styled-components'
import { colors, size } from '../style'

const Wrapper = styled.div`
    position:fixed;
    top:0px;left:0px;right:0px;bottom:0px;
    z-index:1000;
`

const Bg = styled.div`
    position:absolute;
    top:0px;left:0px;right:0px;bottom:0px;
    z-index:1;
    background: #000000;
    opacity: 0.6;
`

const Container = styled.div`
    position:absolute;
    top:0px;left:0px;right:0px;bottom:0px;
    z-index:2;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Contents = styled.div`
    background-color:${colors.white};
    border-radius: 10px;
    min-width:50vw;
    min-height:50vh;
    max-height:90vh;
    max-width:80vw;
    height:100vh;
`

export const Popup = {Wrapper, Bg, Container, Contents}