import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, {useAppState} from '../../../store/store';
import * as ui from '../../../ui'
import { Link } from 'react-router-dom';
import { colors, size } from '../../../style';
import TagMediaListView from '../../../components/TagMediaListView';

const TagListView = styled.ul`
    width:200px;
    padding:30px 10px;
`

const TagView = styled.li`
    cursor:pointer;
    border:1px solid ${colors.primary};
    border-radius: 4px;
    padding:5px 10px;
    margin-bottom:15px;
    font-size:20px;
    color:${colors.primary};

    ${({active})=>active && `
        background-color:${colors.primary};
        color:${colors.white};
        cursor:auto;
    `}
`

const Wrapper = styled.div`
    display:flex;
    width:100%;
    height:calc(100vh - ${size.headerHeight + 20}px);
`

const MediaListWrapper = styled.div`
    flex:1;
    height:100%;
    overflow-y:scroll;
    padding:0px 30px;
`

class TagListScreenModel {
    constructor(
        appState,
        [currentTag, setCurrentTag]
    ){
        this.appState = appState
        this.currentTag = currentTag
        this.setCurrentTag = setCurrentTag
    }

    get tagList(){
        const { tagList} = this.appState

        return tagList
    }

    selectTag = (tag) => {
        this.setCurrentTag(tag)

        console.log("selectTag", tag)
    }

    isActive = (tag) => {
        if(!this.currentTag){
            return false
        }

        return this.currentTag.id === tag.id
    }

    get mediaTagList(){
        if(!this.currentTag){
            return []
        }

        return [this.currentTag]
    }

    get currentTagKey(){
        if(!this.currentTag){
            return -1
        }

        return this.currentTag.id
    }
}

const TagListScreen = () => {

    const viewModel = new TagListScreenModel(useAppState(), useState(undefined))
    const {tagList} = viewModel
    console.log("tagList", tagList)

    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={true}>
            <ui.HeaderContents showed={true}>
                <ui.HeaderTitle>登録ずみタグ</ui.HeaderTitle>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageContents showed={true}>
            <Wrapper>
                <TagListView>
                    {tagList.map(tag=><TagView 
                        key={tag.id}
                        active={viewModel.isActive(tag)}
                        onClick={()=>{
                            viewModel.selectTag(tag)
                        }}
                    >{tag.name}</TagView>)}
                </TagListView>
                <MediaListWrapper key={viewModel.currentTagKey}>
                    <TagMediaListView tagList={viewModel.mediaTagList.map(row=>row.name)} />
                </MediaListWrapper>
            </Wrapper>
        </ui.PageContents>
    </ui.PageWrapper>
}

export default TagListScreen