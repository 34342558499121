

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import * as ui from '../../../ui'
import { colors, size } from '../../../style';
import { Link } from 'react-router-dom';


const Wrapper = styled.div`

`

const ClientRow = styled.div`
    padding:20px;
    border-bottom:1px solid ${colors.border};
    display:flex;
    justify-content:space-between;
    align-items:center;
    transition: background-color 300ms ease;

    ${({ created }) => created && `
        background-color: ${colors.primaryLight};
    `}
`

const DeleteButton = styled.button`
    padding:10px 5px;
    text-align:center;
    width:80px;
    border:1px solid ${colors.danger};
    border-radius: 5px;
    color: ${colors.danger};
    :hover{
        background-color:${colors.danger};
        color:${colors.white};
    }
`

const ClientInfo = styled.div`
    flex:1;
    a{
        color:${colors.primary};
        font-size:20px;
        :hover{
            text-decoration:underline;
        }
    }
`

const ClientList = () => {
    const appState = useAppState()
    const { clientList } = appState

    console.log("render ClientList")

    return <Wrapper>
        {clientList.map(client => renderRow(client))}
    </Wrapper>
}

const deleteClient = (e) => {
    const id = parseInt(e.target.dataset["id"])
    console.log("id", id)
    store.deleteClient(id).then(result => {
        console.log("result", result)
    })
}

const renderRow = (client) => {
    return <ClientRow key={client.id} created={client.created}>
        <ClientInfo>
            <p><Link to={'/client/form/' + client.id}>{client.name}</Link></p>
            <p>{client.email}</p>
        </ClientInfo>
        <DeleteButton data-id={client.id} onClick={deleteClient}>削除</DeleteButton>
    </ClientRow>
}

export default ClientList    