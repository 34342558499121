import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import util from '../../../util'
import { Loader } from '../../../ui'
import store, { AppContext, useAppState } from '../../../store/store'
import { colors } from '../../../style';

const Wrapper = styled.div`
    display:flex;
    width:100%;
    padding: 20px 0px;
    border-bottom: 1px solid #cccccc;
`

const Contents = styled.div`
    flex:1;
`

const Image = styled.div`
    width:100px;
    height:100px;
    margin-right:20px;
    img{
        width:100%;
        height:100%;
    }
`

const CountList = styled.ul`
    display:flex;
    margin-top:10px;
    li{
        margin-right:20px;
        span{
            font-weight:bold;
        }
    }
`

const Caption = styled.p`

`

const VisionList = styled.ul`
    margin-top: 10px;
    li{
        display:inline-block;
        margin-right:20px;
        line-height:1.4;
        font-size:16px;
    }
`

const HashTagList = styled.ul`
    margin-top:10px;
    li{
        display:inline-block;
        margin-right:20px;
        line-height:1.4;
        color:${colors.primary};
        font-size:16px;
    }
`

const MediaView = ({ media }) => {

    const currentVisionList = media.visionList
    const hashTagList = media.hashTagList

    const [visionList, setVisionList] = useState(undefined)
    let _visionList = visionList 

    if(currentVisionList){
        _visionList = currentVisionList 
    }
    
    return <Wrapper>
        <Image>
            <img crossOrigin="Anonymous" src={media.thumbnail_src} onLoad={(e) => {
                if(!media.isTmp){ return }
                var base64Data = util.imgToBase64(e.target, "image/jpeg");
                store.fetchVision(base64Data).then(visionList => {
                    media.visionList = visionList
                    setVisionList(visionList)
                    store.addedVisionList()

                    console.log("fetched vision")
                })
            }} />
        </Image>
        <Contents>
            <CountList>
                <li>いいね <span>{util.keta(media.liked)}</span>件</li>
                <li>コメント <span>{util.keta(media.commented)}</span>件</li>
            </CountList>
            {hashTagList && <HashTagList>
                {hashTagList.map(hashTag => {
                    return <li key={hashTag}>{hashTag}</li>
                })
            }
            </HashTagList>}
            {_visionList && <VisionList>
                {_visionList.map(vision => {
                    return <li key={vision.mid}>{vision.description}</li>
                })
            }
            </VisionList>}
            {!_visionList && <Loader style={{ marginTop: 10 }} />}
        </Contents>
    </Wrapper>
}

export default MediaView