import Influencer from "../entities/Influencer";
import {API} from '../Config'
import ServiceUtil from "./ServiceUtil";
import ProjectInfluencer from "../entities/ProjectInfluencer";

class ProjectInfluencerService {

    fetchList = () =>{
        return fetch(API.resources.projectInfluencer).then((response)=>{
            return response.json();
        }).then(json=>{
            return json.map(row=> new ProjectInfluencer(row))
        })
    }

    create = (client_id, influencer_id) =>{
        return ServiceUtil.post(API.resources.projectInfluencer, {
            client_id: client_id,
            influencer_id: influencer_id,
        }).then(res=> res.json()).then(json=>new ProjectInfluencer(json, true))
    }

    delete = (id) => {
        return fetch(`${API.resources.projectInfluencer}/${id}`, {
            method: "DELETE",
        }).then(res=>res.json())
    }
}

export const projectInfluencerService = new ProjectInfluencerService()
