import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { colors, size } from '../../../style'
import store, { useAppState } from '../../../store/store'
import * as ui from '../../../ui'
import util from '../../../util'
import AttributesView from '../components/AttributesView';
import { useHistory, useParams, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import MediaView from '../search/MediaView';

const UserView = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items: flex-start;
    padding-top:20px;
`

const UserInfo = styled.div`
    flex:1;
    margin-bottom:20px;
    h3{
        font-size:20px;
        span{
            font-size:15px;
            color: #999999;
        }
    }
    h4{
        margin-top:20px;
        font-size:20px;
    }
    p{
        margin-top:10px;
    }
`

const CountList = styled.ul`
    display:flex;
    flex-wrap:wrap;
    margin-top:10px;
    li{
        margin-right:20px;
        font-size:12px;
        span{
            font-weight:bold;
            font-size:18px;
        }
    }
`

const ProfileImage = styled.div`
    width:100px;
    height:100px;
    border-radius:50px;
    border:4px solid #9999ff;
    overflow:hidden;
    margin-right:20px;
    img{
        width:100%;
        height:100%;
    }
`

const HashTagListView = styled.ul`
    display:flex;
    flex-wrap:wrap;
    margin-bottom:20px;
    li{
        display:inline-block;
        margin-left:10px;
        margin-top:10px;
        color:${colors.primary};
        font-size:16px;
    }
`

const InfluencerViewScreen = () => {

    const [showed, setShowed] = useState(false)
    const appState = useAppState()

    useEffect(() => {
        setTimeout(() => {
            setShowed(true)
        }, 100)
    }, []);

    const history = useHistory()
    const handleBack = () => {
        setShowed(false)
        setTimeout(() => {
            history.replace("/influencer")
        }, 300)
    }

    let { id } = useParams()
    const influencer = store.findInfluencer(id)

    if (influencer == undefined) {
        return <ui.Loader />
    }

    const mediaList = appState.mediaList.slice().filter(row => row.influencerId === influencer.id)
    const visionList = util.visionList(mediaList)
    const hashTagList = util.hashTagList(mediaList)

    const handleDelete = () => {
        store.deleteInfluencer(influencer.id)
        handleBack()
    }

    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={showed}>
            <ui.HeaderContents showed={showed}>
                <ui.HeaderBackButton onClick={handleBack}>&lt; 戻る</ui.HeaderBackButton>
                <ui.HeaderButtonDanger onClick={handleDelete}>削除</ui.HeaderButtonDanger>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageBgLayer showed={showed} />
        <ui.PageContents showed={showed}>
            <UserView>
                <ProfileImage>
                    <img src={influencer.profilePic} />
                </ProfileImage>
                <UserInfo>
                    <h3>{influencer.fullName} <span><a href={`https://www.instagram.com/${influencer.username}/`} target="_blank">{influencer.username}</a></span></h3>
                    <CountList>
                        <li>投稿 <span>{util.keta(influencer.allMediaCount)}</span>件</li>
                        <li>フォロワー <span>{util.keta(influencer.follower)}</span>人</li>
                        <li>フォロー <span>{util.keta(influencer.follow)}</span>人</li>
                        <li>平均いいね <span>{util.keta(util.avarageLiked(mediaList))}</span>人</li>
                        <li>平均コメント <span>{util.keta(util.avarageCommented(mediaList))}</span>人</li>
                    </CountList>
                    <p>{influencer.biography}</p>
                </UserInfo>
            </UserView>

            {hashTagList.length > 0 && <HashTagListView>
                {hashTagList.map(row=><li key={row.hashTag}>{row.hashTag}({row.count})</li>)}
            </HashTagListView>}

            {visionList.length > 0 && <AttributesView visionList={visionList} />}

            {mediaList.map(media => <MediaView media={media} key={media.id} />)}

        </ui.PageContents>
    </ui.PageWrapper>
}


export default InfluencerViewScreen