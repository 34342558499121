import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import store, { useAppState } from '../../../store/store';
import * as ui from '../../../ui'
import { Link } from 'react-router-dom';
import ProjectForm from './ProjectForm'
import { colors, size } from '../../../style'
import { useParams, useHistory } from 'react-router-dom';
import TagMediaListView from '../../../components/TagMediaListView';
import ECLinkForm from './ECLinkForm';
import ProjectImageForm from './ProjectImageForm';
const { Popup } = ui

const ContentsContainer = styled.div`
    width:100%;
    height:100%;
`

const ProjectFormScreen = () => {

    const [showed, setShowed] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [assignedInfluencerList, setAssignedInfluencerList] = useState([])
    const appState = useAppState()
    let { id } = useParams()
    id = parseInt(id)
    const project = appState.projectList.find(row => row.id === id)

    useEffect(() => {
        setTimeout(() => {
            setShowed(true)
        }, 100)

        const influencerList = appState.projectInfluencerList
            .filter(row => row.project_id === id)
            .map(row => appState.influencerList.find(influencer => influencer.id === row.influencer_id))

        setAssignedInfluencerList(influencerList)
    }, []);

    const history = useHistory()
    const handleBack = () => {
        setShowed(false)
        setTimeout(history.goBack, 300)
    }

    const handleAddInfluencer = (influencer) => {
        const _assignedInfluencerList = assignedInfluencerList.slice()
        _assignedInfluencerList.unshift(influencer)
        setAssignedInfluencerList(_assignedInfluencerList)
    }

    const handleDeleteInfluencer = (influencer) => {
        const _assignedInfluencerList = assignedInfluencerList.slice().filter(row => row.id !== influencer.id)
        setAssignedInfluencerList(_assignedInfluencerList)
    }

    const handleClosePopup = () => {
        store.updateProject(project.id, project.name, project.client_id, assignedInfluencerList)
        setShowPopup(false)
    }

    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={showed}>
            <ui.HeaderContents showed={showed}>
                <ui.HeaderBackButton onClick={handleBack}>&lt; 戻る</ui.HeaderBackButton>
                <ui.HeaderSpacer />
                <ui.HeaderTitleCenter>案件情報</ui.HeaderTitleCenter>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageBgLayer showed={showed} />
        <ui.PageContents style={{ paddingRight: 0 }} showed={showed}>
            <Contents setShowPopup={setShowPopup} assignedInfluencerList={assignedInfluencerList} />
        </ui.PageContents>

    </ui.PageWrapper>
}

const Contents = ({ setShowPopup, assignedInfluencerList }) => {

    let { id } = useParams()
    const appState = useAppState()
    const project = appState.projectList.find(row => row.id == id)

    if (id !== undefined && project === undefined) {
        return <ui.Loader />
    }

    return <ContentsContainer>
        <ProjectForm project={project} />
        {project && <ECLinkForm project={project} />}
        {project && <ProjectImageForm project={project} />}
        {project && <TagMediaListView tagList={project.tagList} />}
    </ContentsContainer>
}


export default ProjectFormScreen