import React, { useState, useEffect, useContext } from 'react';
import store, { useAppState } from '../../../store/store'
import * as ui from '../../../ui'
import { Link, useHistory } from 'react-router-dom';

import InfluencerRow from '../components/InfluencerRow';

const { RowWrapper, RowContainer, UserInfo, CountList, ProfileImage } = ui.InfluencerList


const InfluencerListScreen = () => {

    const appState = useAppState()
    const { influencerList, mediaList } = appState

    const history = useHistory()

    const handleSelectInfluencer = (influencer) => {
        store.currentInfluencer = undefined
        history.push('/influencer/view/' + influencer.id)
    }

    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={true}>
            <ui.HeaderContents showed={true}>
                <ui.HeaderTitle>インフルエンサー <span>({influencerList.length} 人)</span></ui.HeaderTitle>
                <Link to="/influencer/search"><ui.HeaderButton>登録</ui.HeaderButton></Link>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageContents showed={true}>
            {influencerList.map(influencer => <InfluencerRow
                key={influencer.id}
                influencer={influencer}
                onClickName={handleSelectInfluencer}
            />)}
        </ui.PageContents>
    </ui.PageWrapper>
}

export default InfluencerListScreen