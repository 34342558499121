class Tag {

    constructor(data) {
        this._data = data
    }

    get id() {
        return this._data.id
    }

    get name() {
        return this._data.name
    }
}

export default Tag