import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import store, {useAppState} from '../../../store/store';
import * as ui from '../../../ui'
import { Link, useHistory } from 'react-router-dom';
import ClientForm from './ClientForm'
import { colors, size } from '../../../style'
import { useParams} from 'react-router-dom';
import InfluencerRow from '../../influencer/components/InfluencerRow';

const ContentsWrapper = styled.div`
    display:flex;
    width:100%;
    height:100%;
`

const InfluencerView = styled.div`
    flex:1;
    height:100%;
    overflow-y:scroll;
    padding:30px;
`

class ClientFormScreenModel{
    constructor(appState, clientId){
        this.appState = appState
        this.clientId = clientId
    }

    get client(){
        return this.appState.clientList.find(row=>row.id == this.clientId)
    }

    get suggestInflunecerList(){
        const {appState, client} = this
        if(!client){
            return []
        }

        const { mediaList, influencerList } = appState
        const { tagList } = client

        const _mediaList = mediaList.slice().filter(row => row.hasTag(tagList))
        const influencerIdList = [...new Set(_mediaList.map(row=>row.influencerId))];
        const _influencerList = influencerIdList
        .map(row=>influencerList.find(i=>i.id===row))
        .sort((a, b)=>{
            return b.follower > a.follower
        })

        console.log("_mediaList", _influencerList)

        return _influencerList
    }

    handleSelectInfluencer = (influencer) => {

    }
}

const ClientFormScreen = () => {

    const [showed, setShowed] = useState(false)
    const { id } = useParams()
    const viewModel = new ClientFormScreenModel(useAppState(), id)
    const {suggestInflunecerList} = viewModel

    console.log("suggestInflunecerList", suggestInflunecerList)

    useEffect(() => {
        setTimeout(()=>{
            setShowed(true)
        },100)
    }, []);

    const history = useHistory()
    const handleBack = () => {
        setShowed(false)
        setTimeout(history.goBack, 300)
    }



    return <ui.PageWrapper>
        <ui.HeaderWrapper showed={showed}>
            <ui.HeaderContents showed={showed}>
                <ui.HeaderBackButton onClick={handleBack}>&lt; 戻る</ui.HeaderBackButton>
                <ui.HeaderSpacer />
                <ui.HeaderTitleCenter>クライアント情報</ui.HeaderTitleCenter>
            </ui.HeaderContents>
        </ui.HeaderWrapper>
        <ui.PageBgLayer showed={showed} />
        <ui.PageContents showed={showed}>
            <Contents viewModel={viewModel} />
        </ui.PageContents>
    </ui.PageWrapper>
}

const Contents = ({viewModel}) => {

    const {client, clientId, suggestInflunecerList, handleSelectInfluencer} = viewModel

    if(clientId !== undefined && client === undefined){
        return <ui.Loader />
    }

    return <ContentsWrapper>
        <ClientForm client={client} />
        <InfluencerView>
            <ui.PageTitle>提案インフルエンサー <span>({suggestInflunecerList.length})</span></ui.PageTitle>
            {suggestInflunecerList.map(influencer => <InfluencerRow
                key={influencer.id}
                influencer={influencer}
                onClickName={handleSelectInfluencer}
            />)}
        </InfluencerView>
    </ContentsWrapper>
}

export default ClientFormScreen