class Client {

    constructor(data, created = false) {
        this._data = data
        this.created = created
    }

    get id() { return this._data.id }
    get name() { return this._data.name }
    get email() { return this._data.email }
    get tagList(){
        if(!this._data.tag_list){
            return []
        }

        try{
            return JSON.parse(this._data.tag_list)
        }catch(e){
            return []
        }
    }

}

export default Client